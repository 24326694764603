













import { Component, Vue } from 'vue-property-decorator'

import SubscriptionTypeRuleForm from '@/partials/forms/Events/SubscriptionTypeRuleForm.vue'

@Component({
  components: {
    SubscriptionTypeRuleForm
  }
})
export default class SubscriptionRefundCreate extends Vue {

}
